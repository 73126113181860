import {AccountConstants} from "finhelper"

class AccountsData
{
  static lastId = 10;

  static bankSubAccountId = 13;

  static data = [
    {
      "id": 1,
      "user_id": 3,
      "name": "Кошелек",
      "comment": null,
      "type_id": 1,
      "type_text": "Наличные",
      "number": null,
      "system_name": null,
      "bik": null,
      "correspondent_account": null,
      "inn": null,
      "kpp": null,
      "swift_code": null,
      "bank_id": null,
      "bank_text": "",
      "custom_bank_id": null,
      "is_visible": true,
      "accounts": [
        {
          "id": 1,
          "name": "Наличные RUB",
          "account_id": 1,
          "currency_id": 1,
          "sum": 560330,
          "original_sum": 560330,
          "is_temp": false,
          "type_id": null,
          "item_id": null,
          "item_type": null,
          "is_visible": true,
          "is_visible_spendings": true,
          "is_api": false,
          "currency": {
            "id": 1,
            "name": "Российский рубль",
            "code": "RUB",
            "sign": "₽",
            "order": 1
          }
        },
      ]
    },
    {
      "id": 6,
      "user_id": 3,
      "name": "Сбер",
      "comment": null,
      "type_id": 2,
      "type_text": "Банковский счёт",
      "number": "40817810338284914146",
      "system_name": null,
      "bik": null,
      "correspondent_account": null,
      "inn": null,
      "kpp": null,
      "swift_code": null,
      "bank_id": AccountConstants.SBER_BANK,
      "bank_text": "",
      "custom_bank_id": null,
      "is_visible": true,
      "accounts": [
        {
          "id": AccountsData.bankSubAccountId,
          "name": "Сбер RUB",
          "account_id": 6,
          "currency_id": 1,
          "sum": 12670670,
          "original_sum": 12670670,
          "is_temp": false,
          "type_id": null,
          "item_id": null,
          "item_type": null,
          "is_visible": true,
          "is_visible_spendings": true,
          "is_api": false,
          "currency": {
            "id": 1,
            "name": "Российский рубль",
            "code": "RUB",
            "sign": "₽",
            "order": 1
          }
        },
      ]
    },
    {
      "id": 7,
      "user_id": 3,
      "name": "Алььфа инвестиции",
      "comment": null,
      "type_id": 4,
      "type_text": "Брокерский счёт",
      "number": "",
      "system_name": null,
      "bik": null,
      "correspondent_account": null,
      "inn": null,
      "kpp": null,
      "swift_code": null,
      "bank_id": AccountConstants.ALPHA_BROKER,
      "bank_text": "",
      "custom_bank_id": null,
      "is_visible": true,
      "accounts": [
        {
          "id": 16,
          "name": "Алььфа RUB",
          "account_id": 7,
          "currency_id": 1,
          "sum": 100000,
          "original_sum": 100000,
          "is_temp": false,
          "type_id": null,
          "item_id": null,
          "item_type": null,
          "is_visible": true,
          "is_visible_spendings": true,
          "is_api": false,
          "currency": {
            "id": 1,
            "name": "Российский рубль",
            "code": "RUB",
            "sign": "₽",
            "order": 1
          }
        },
      ]
    },
    {
      "id": 2,
      "user_id": 3,
      "name": "Мой счет",
      "comment": null,
      "type_id": AccountConstants.DIGIT_MONEY,
      "type_text": "Брокерский счёт",
      "number": null,
      "system_name": null,
      "bik": null,
      "correspondent_account": null,
      "inn": null,
      "kpp": null,
      "swift_code": null,
      "bank_id": 3003,
      "bank_text": "Тинькофф брокер",
      "custom_bank_id": null,
      "is_visible": true,
      "accounts": [
        {
          "id": 4,
          "name": "Мой счет",
          "account_id": 2,
          "currency_id": 1,
          "sum": 100000,
          "original_sum": 100000,
          "is_temp": false,
          "type_id": null,
          "item_id": null,
          "item_type": null,
          "is_visible": true,
          "is_visible_spendings": true,
          "is_api": true,
          "currency": {
            "id": 1,
            "name": "Российский рубль",
            "code": "RUB",
            "sign": "₽",
            "order": 1
          }
        },
      ]
    },
  ]
}

export default AccountsData;