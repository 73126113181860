import styled from 'styled-components'

export const Wrapper = styled.div`
    flex-grow: 1;
    margin: 0 auto;
    position: relative;
    width: auto;
`


export const Container = styled.div`
    border-radius: 10px;
    //max-width: 500px;
`

export const Text = styled.div`
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    color: #000;
    text-align: left;
`

export const GreyText = styled.div`
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    color: #7D7D7D;
    text-align: left;
`

export const ButtonContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: #fff;
    max-width: 490px;
    margin-top: 20px;
    margin-bottom: 20px;
`
export const Button = styled.button`
    color: #fff;
    background: #4378FF;
    display: inline-block;
    cursor: pointer;
    font-size: 18px;
    line-height: 40px;
    //padding: 8px 18px;
    white-space: nowrap;
    height: 40px;
    border-radius: 10px;
    border: none;
    transition: .3s all;
    outline: none;
    margin: 20px 20px 0 20px;
    flex: 1;
`

export const Form = styled.form`
    
`

export const Title = styled.h3`
    
`

export const HeaderForgetContainer = styled.div`
    display: block;
    width: 100%;
    text-align: right;
`
export const HeaderForget = styled.div`
    text-align: right;
    display: inline-block;
    color: #4378FF;
    font-size: 14px;
    margin-right: 20px;
    margin-bottom: 10px;
    cursor: pointer;
`