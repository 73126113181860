import React, {useEffect, useState} from 'react';
import {BlockLoader, ModalLoader as Loader, Overlay, WrapperLoader} from './styles';
import Portal from "../portal/Portal";

const ModalLoader = ({ show }) => {
  const [fade, setFade] = useState(show);

  useEffect(() => {
    setFade(show);
  }, [show]);

  return (
    <Portal id="loader">
      <Overlay show={show}>
        <WrapperLoader>
          <BlockLoader>
            <Loader />
          </BlockLoader>
        </WrapperLoader>
      </Overlay>
    </Portal>
  );
};

export default ModalLoader;
