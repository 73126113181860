import React from 'react';
import {Container} from './styles'
import {Outlet} from 'react-router-dom';

const Layout = ({}) =>
{
  return <Container>
    <Outlet/>
  </Container>;
}

export default Layout;
