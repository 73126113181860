const logMessage = async () => {
  try
  {
    if (window.performance && window.performance.memory) {
      console.log("Memory Usage:", window.performance.memory.usedJSHeapSize / (1024 * 1024), "MB");
      console.log("Total Memory:", window.performance.memory.totalJSHeapSize / (1024 * 1024), "MB");
      console.log("Heap Size Limit:", window.performance.memory.jsHeapSizeLimit / (1024 * 1024), "MB");
    } else {
      console.log("Memory API not supported in this browser.");
    }

    const quota = await window.navigator.storage.estimate();

    console.log("Total space for storage", quota.quota / (1024 * 1024), "MB");
    console.log("Usage space of storage", quota.usage / (1024 * 1024), "MB");
  }catch (e){
    console.warn(e)
  }
}

logMessage();
setInterval( () => {
  logMessage();
}, 1000 * 60)