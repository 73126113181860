import {applyMiddleware, compose, createStore} from 'redux'
import createSagaMiddleware from 'redux-saga'
import webSocketMiddleware from './middlewares/webSocketMiddleware';

import rootReducer from './reducers'
import rootSaga from './sagas'

export default (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware()

  const composedEnhancers = compose(applyMiddleware(
    sagaMiddleware,
    webSocketMiddleware
  ))

  const store = createStore(rootReducer(), initialState, composedEnhancers)

  sagaMiddleware.run(rootSaga)

  // if (process.env.NODE_ENV !== 'production') {
  //   if (module.hot) {
  //     module.hot.accept('./reducers', () => {
  //       store.replaceReducer(rootReducer)
  //     })
  //   }
  // }

  return store
}
