import styled from 'styled-components'

let link = require('./images/blackswan-w.svg').default;
let logo = require('./images/whiteswan-b.svg').default;
let icon = require('./images/icon-login.svg').default;
let right = require('./images/icon-right.svg').default;

export const HeaderWrapper = styled.div`
    background: #fff;
    height: 100px;
`
export const HeaderContainer = styled.div`
    background: #fff;
    padding: 21px 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
    line-height: 0;
    z-index: 20;
    
    &.scrolled{
      position: fixed;
      top: 0px;
    }
`

export const Wrap = styled.div`
  max-width: 1440px;
  padding: 0 50px;
  margin: 0 auto;
  position: relative;
`
export const ModalText = styled.div`
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    color: #000;
`

export const HeaderLogo = styled.div`
    display: inline-block;
    
    a{
      background: no-repeat url(${logo}) center center;
      width: 147px;
      height: 22px;
      display: block;
      cursor: pointer;
      overflow: hidden;
      text-indent: 150px;
      white-space: nowrap;
      text-decoration: none;
    }
`

export const HeaderMenu = styled.div`
    position: absolute;
    left: 275px;
    top: 50%;
    transform: translate3d(0,-150%,0);
    opacity: 0;
    transition: .3s all;
    
    .scrolled > &{
      transform: translate3d(0,-50%,0);
      opacity: 1;
    }
    
    & > div > a{
        padding: 18px;
        font-size: 18px;
        line-height: 28px;
        display: inline-block;
        margin-right: 44px;
        position: relative;
        color: #202020;
        text-decoration: none;
        
        :hover{
          color: rgba(32,32,32,.6);
          
          :before{
            background: #4378FF;
          }
        }
        
        :before{
          position: absolute;
          left: 18px;
          right: 18px;
          top: 0;
          height: 4px;
          background: 0 0;
          display: block;
          content: "";
          transition: .3s all;
        }
    }
`

export const HeaderPersonal = styled.div`
    position: absolute;
    top: 50%;
    right: 300px;
    transform: translate3d(0, -50%, 0);
    
     a{
      color: #fff;
      background: #4378FF;
      display: inline-block;
      cursor: pointer;
      font-size: 18px;
      line-height: 32px;
      padding: 8px 18px;
      white-space: nowrap;
      height: 48px;
      border-radius: 10px;
      border: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      transition: .3s all;
      box-sizing: border-box;
    }
`

export const HeaderOther = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate3d(0,-50%,0);
    
    & > a:hover .other-swan-link:after{
      left: 175px;
      transition: .3s all;
      filter: grayscale(0%) brightness(100%);
    }
`

export const HeaderOtherLink = styled.span`
    width: 270px;
    display: block;
    height: 48px;
    border-radius: 10px 0 0 10px;
    overflow: hidden;
    text-indent: 250px;
    white-space: nowrap;
    cursor: pointer;
    background: #000 no-repeat 20px center url(${link});
    
    
    :after{
      filter: grayscale(100%) brightness(200%);
      background: no-repeat url(${right}) center center;
      width: 22px;
      height: 15px;
      position: absolute;
      display: block;
      content: "";
      left: 170px;
      transition: .3s all;
      top: 55%;
      transform: translate3d(0, -50%, 0);
    }
`

export const HeaderOtherComment = styled.span`
    position: absolute;
    left: 20px;
    top: 52px;
    font-size: 12px;
    line-height: 16px;
    display: block;
    color: #929292;
    transition: .3s opacity;
`

