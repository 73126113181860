import {createReducer} from 'redux-act'
import * as actions from '../actions/scenario'
import moment from "moment/moment";

const getDefaultAvatar = () =>
{
  return process.env.REACT_APP_API_URL + '/images/avatar/default.svg';
}

let data = {
  avatar: getDefaultAvatar(),
  data: {
    id: null
  },
  formLoading: true,
  tutorial: false,
  demo: false,
  helperClose: () => {

  },
  helperGotoStep: () => {

  }
};

const handleStartTutorial = (state, data) =>
{
  if (data)
  {
    let json = data.scenario;

    if (json && json.name)
    {
      switch (json.name)
      {
        case 'register':
          return {
            ...state,
            tutorial: true
          }
          break;
        case 'demo':
          return {
            ...state,
            demo: true
          }
          break;
      }
    }
  }

  return state;
}

const handleEndTutorial = (state) =>
{
  return {
    ...state,
    tutorial: false
  }
}

const handleStartDemo = (state, data) =>
{
    return {
      ...state,
      demo: true
    }
}

const handleEndDemo = (state) =>
{
  return {
    ...state,
    demo: false
  }
}

const bindHelperGotoFunc = (state, func) => {
  if(typeof func === 'function')
  {
    return {
      ...state,
      helperGotoStep: func
    }
  }else{
    return state;
  }
};

const bindHelperCloseFunc = (state, func) => {
  if(typeof func === 'function')
  {
    return {
      ...state,
      helperClose: func
    }
  }else{
    return state;
  }
};

const handleFormAvatar = (state, data) => {
  return {
    ...state,
    avatar: data.avatar
  }
};

const handleFormDataStatus = (state, status) => {
  return {
    ...state,
    formLoading: status
  }
};

const handleFormData = (state, data) => {

  let form = {
    id: data.id,
    first_name: data.first_name,
    last_name: data.last_name,
    middle_name: data.middle_name,
    email: data.email,
    phone: data.phone,
    retired_age: data.retired_age,
    dead_age: data.dead_age,
    currency_id: data.currency_id,
    language_id: data.language_id,
    data: data.data,
    percent_positive: data.percent_positive,
    percent_neutral: data.percent_neutral,
    percent_negative: data.percent_negative,
    index_positive_income: data.index_positive_income,
    index_neutral_income: data.index_neutral_income,
    index_negative_income: data.index_negative_income,
    index_outcome: data.index_outcome,
    vk: data.vk,
    fb: data.fb,
    twit: data.twit,
    points: data.points,
  };



  if (data['birth_at_date'] && typeof data['birth_at_date'] === 'string' && data['birth_at_date'].length > 0)
  {
    let date = moment(data['birth_at_date'], 'DD.MM.YYYY');
    form['birth_at'] = date.format('YYYY-MM-DD');
    form['birth_at_user'] = date.format('DD.MM.YYYY');
    form['birth_at_date'] = date.toDate();
  } else
  {
    form['birth_at'] = '';
    form['birth_at_user'] = '';
    form['birth_at_date'] = null;
  }

  if (data['start_enter_at_date'] && typeof data['start_enter_at_date'] === 'string' && data['start_enter_at_date'].length > 0)
  {
    let date = moment(data['start_enter_at_date'], 'DD.MM.YYYY');
    form['start_enter_at'] = date.format('YYYY-MM-DD');
    form['start_enter_at_user'] = date.format('DD.MM.YYYY');
    form['start_enter_at_date'] = date.toDate();
  } else {
    form['start_enter_at'] = '';
    form['start_enter_at_user'] = '';
    form['start_enter_at_date'] = null;
  }

  let months = moment.months();

  months.map((item, key) =>
  {
    if(key === data['career_start_month'])
    {
      form.career_start_month = {};
      form.career_start_month.id = key;
      form.career_start_month.name = item;
    }
  });

  return {
    ...state,
    formLoading: false,
    avatar: data.avatar,
    data: form
  }
}


const reducer = createReducer({
  [actions.handleFormData]: handleFormData,
  [actions.handleFormAvatar]: handleFormAvatar,
  [actions.handleFormDataStatus]: handleFormDataStatus,
  [actions.bindHelperGotoFunc]: bindHelperGotoFunc,
  [actions.bindHelperCloseFunc]: bindHelperCloseFunc,
  [actions.handleStartTutorial]: handleStartTutorial,
  [actions.handleEndTutorial]: handleEndTutorial,
  [actions.handleStartDemo]: handleStartDemo,
  [actions.handleEndDemo]: handleEndDemo,
}, data);

export default reducer
