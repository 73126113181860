import React from 'react'

export default {
  common: {
    shortMode: 'Short mode',
    extendedMode: 'Advanced Mode',
    deleteButton: 'Delete',
    cancelButton: 'Cancel',
    saveButton: 'Save',
    saveAndContinueButton: 'Save and continue',
    editButton: 'Edit',
    calcButton: 'Calculate',//цель
    showBy: 'Show by',
    noData: 'No data available',
    forbidden: 'You dont have enough rights, log in',
    notFound1: 'Nothing found',
    notFound2: 'Try going back or look for something else.',
    notFound3: 'Error Code: 404',
    notFound4: 'Main page',
    editPhoto: 'Photo Editing',
    back: 'Go back',
    loadPhoto: 'Upload a photo',
    dropPhoto: 'Drag your photo here',
    mb: 'mb',
    sizeMax: 'Image size no more than',
    sizeMin: 'Image size not less than 0 MB',
    format: 'Image format jpg, jpeg, gif or png',

  },
  accounts: {
    title: 'Accounts / Assets / Liabilities',
    accounts: 'Accounts',
    archiveAccounts: 'Account Archive',
    cash: 'Cash',
    bank: 'Banking',
    broker: 'Brokerage',
    user: 'Custom',
    addAccount: 'Add account',
    editAccount: 'Edit account',
    newAccount: 'New account',
    addActive: 'Add asset',
    editActive: 'Edit asset',
    newActive: 'New asset',
    addOblig: 'Add liability',
    editOblig: 'Edit liability',
    newOblig: 'New liability',
    actives: 'Assets',
    activesPrivate: 'Private',
    activesInvest: 'Investment (non-financial)',
    archiveActives: 'Asset archive',
    obligs: 'Liabilities',
    typeObligs: 'Type of liability',
    archiveObligs: 'Liabilities archive',
    currenctObligs: 'Current',
    longObligs: 'Long-term',
    subAccounts: 'Subaccounts',
    nameSubAccount: 'Name of the subaccount',
    balance: 'Balance',
    currency: 'Currency',
    nameDeposit: 'Deposit name',
    descDeposit: 'Description of the deposit',
    accountDeposit: 'The account from which the deposit was made',
    sumDeposit: 'Deposit amount',
    startDeposit: 'Date and time of deposit start',
    endDeposit: 'Date and time of deposit completion',
    procentType: 'Type of interest',
    procentYear: 'Interest (per annum)',
    periodEarn: 'Accrual period',
    accountDepositEarn: 'Interest transfer account',
    nameAccount: 'Account name',
    typeAccount: 'Account type',
    bankName: 'Bank name',
    brokerName: 'Broker Name',
    addSubAccount: 'Add Subaccount',
    temp: 'Temporary',
    currencySum: 'Amount currency',
    deletingAccount: 'Deleting account',
    deleteAccount: 'Delete account',
    subAccount: 'Subaccount',
    confirmDeleteAccount: 'Are you sure you want to delete the account?',
    confirmDeleteSubAccount: 'Are you sure you want to delete the subaccount?',
    typeActive: 'Type of asset',
    buyType: 'Method of acquiring an asset',
    typeObject: 'Type of object',
    groupInvest: 'Investment Group',
    name: 'Name',
    desc: 'Description',
    buyActive: 'Byu asset',
    getActive: 'Receipt of an asset',
    buyAccount: 'Purchase account',
    debtAccount: 'Withdrawal account',
    sumIn: 'Addition amount',
    dateIn: 'Date and time of posting',
    sellActive: 'Sell asset',
    sellAccount: 'Sales account',
    sellPrice: 'Selling price',
    sellDate: 'Date and time of sale',
    buyPrice: 'Purchase price',
    buyDate: 'Date and time of purchase',
    valuePrice: 'Estimated price',
    getDate: 'Date and time of receipt',
    addDeposit: 'Add deposit',
    newInvest: 'New investment',
    editInvest: 'Edit investments',
    typeInvest: 'Type of investments',
    tag: 'Tag',
    classification: 'Classification',
    addInvesting: 'Add investment',
    activeName: 'Name of asset',
    activeDesc: 'Description',
    paymentSum: 'Amount of payment',
    period: 'Periodicity',
    start: 'Start date and time',
    end: 'End date and time',
    newOperation: 'New operation',
    editOperation: 'Edit operation',
    confirm: 'Are you sure you want to delete the translation?',
    transfer: 'Transfer between accounts',
    all: 'Total',
    addSubaccount: 'Add subaccount',
    current: 'Current',
    invest: 'Investment',
    other: 'Other',
    brokerAccount: 'Brokerage accounts',
    long: 'Long-term',
    private: 'Private',
    operations: 'Operation',
    operationAccount: 'Transaction account',
    logsDate: 'Date and time',
    logsAccount: 'Account',
    logsReason: 'Purpose of payment',
    logsSum: 'Sum',
    logsCommission: 'Commission',
    logsBalance: 'Balance',
  },
  dashboard: {
    incomesOutcomes: 'Budget',
    payments: 'This months dues',
    paymentsPlan: 'Mandatory payments to comply with the plan',
    priceChange: 'Change in the value of assets',
    portfolio: 'Portfolio',
    currentPriceActive: 'Current value of assets',
    avgPriceActive: 'Average asset value',
    period: 'Period',
    inc: 'Inc.',
    outc: 'Exps.',
  },
  sidebar: {
    wealthy: 'Wealth',
    current: 'Current',
    invest: 'Investment',
    broker: 'Brokerage',
    other: 'Other',
    private: 'Private',
    long: 'Long-term',
    oblig: 'Liabilities',
    brokerAccount: 'Brokerage accounts',
    cash: 'Cash',
    cards: 'On cards',
    user: 'User accounts',
    actives: 'Assets'
  },
  menu: {
    dashboard: 'Dashboard',
    dynamic: 'Wealth dynamic',
    accounts: <>Accounts /<br/> Assets /<br/> Liabilities</>,
    operations: 'Operations',
    clients: 'Clients',
    fillProfile: 'Fill out profileь',
    incomes: 'Income',
    outcomes: 'Expenses',
    plan: 'Planning',
    goals: 'Goals',
    charts: 'Charts',
    investList: 'List of investments',
    diagrams: 'Diagrams',
    view: 'Review',
    incomesOutcomes: 'Budget',
    planing: 'Planning',
    invest: 'Investments',
    config: 'Settings',
    support: 'Support',
    profile: 'Profile',
    logout: 'Exit',
  },
  mainPage: {
    title: 'Service for financial accounting Whiteswan',
    mainTitle: 'Service for personal financial planning',
    mainTitle2: 'Cost control<br/>and savings planning platform',
    functions: 'What features are already available in&nbsp;whiteswan',
    functions1: 'Manual entry of income and expenses',
    functions2: 'Accounting for assets and liabilities',
    functions3: 'Planning expenses and income',
    functions4: 'Calculation of financial goals',
    functions5: 'Accounting for financial and non-financial investments',
    functions6: 'Visualization of the investment portfolio of financial goals',
    block1: 'Financial condition scoring system',
    block11: 'Analyze assets and liabilities',
    block12: 'Structure assets by groups: current, investment, personal use. Conduct an analysis in the context of obligations for each group.',
    block13: 'Daily dynamic calculation of financial ratios will help to assess the current financial condition.',
    block2: 'Synchronization with the bank',
    block21: 'Control your expenses',
    block22: 'Look at expenses from the other side - every unnecessary expense pushes back the fulfillment of goals, find your balance. Analyze changes within different spending groups over time. The result will allow predicting net cash flow (the difference between current income and current expenses)',
    block3: 'Smart Savings',
    block31: 'Write down your goals and make a financial plan',
    block32: 'A positive cash flow has been created (current income is greater than current expenses). See if it\'s enough to meet your financial goals. Everything is calculated automatically, but you can use your plans entered manually. Follow the dynamics of the implementation of the financial plan - make adjustments.',
    block4: 'Portfolio',
    block41: 'Follow the dynamics of the investment portfolio',
    block42: 'A separate investment portfolio is created for each goal. Select financial products in terms of personal risk acceptance and goal achievement time, and we will show the dynamics for each group.',
    haveAdviser: 'Have a financial advisor?',
    shareLink: 'Send a link to the CRM block for financial advisors and work with an advisor on a single platform.',
    visit: 'Go to site',
    proposal: 'We are waiting for your suggestions',
    company: 'ООО «ДАТА ТЕКНОЛОДЖИС ЭНД АНАЛИТИКС КОНСАЛТИНГ»',
    inn: 'ИНН: 7604323536, ОГРН: 1177627011836',
    agreement: 'Terms of use',
    politic: 'Data processing policy',
  },
  auth: {
    register: 'Registration',
    enter: 'Enter',
    login: 'Login',
    haveProfile: 'Do you have an account?',
    crm: 'CRM for financial advisors',
    cancel: 'Cancel',
    forgotPassword: 'Forgot your password',
    toRegister: 'Register',
    promoCode: 'Promocode',
    password: 'Password',
    passwordRepeat: 'Password repeat',
    issue: <>(mail to <a href="mailto:info@whiteswan.finance">info@whiteswan.finance</a> if the code does not come)</>,
    code: 'Code',
    codeAdvise: 'Enter the code sent to',
    confirm: 'Confirm',
    fa: 'Code 2FA',
    createNewPassword: 'Create a new password',
    newPassword: 'New password',
    newPasswordRepeat: 'Repeat new password',
    save: 'Save',
    restore: 'Reestablish',
  },
  config: {
    outcomes: 'Expense category settings',
    outcomes2: 'Select categories to display on charts. You can also add your own categories.',
    outcomes3: 'Systemic',
    outcomes4: 'Custom',
    createCategory: 'Create category',
    editCategory: 'Edit category',
    incomes: 'Income category settings',
    incomes2: 'Select categories to display on charts. You can also add your own categories.',
    incomes3: 'Systemic',
    incomes4: 'Custom',
    name: 'Name',
    tags: 'Tags',
    budgetTemplate: 'Budget Templates',
    addCategory: 'Add category',
    add: 'Add',
    contactApp: 'Application Interaction',
    zenmoney: 'ZenMoney',
    configBind: 'You can set up an association to receive expenses and income so you don\'t have to manually add these details.',
    addUser: 'Add users',
    bind: 'Tie',
    unbind: 'Untie',
    tinkoff: 'Tinkoff Investments',
    configBindTinkoff: 'You can set up a link to receive brokerage account transactions so you don\'t have to manually add this information.',
    token: 'Token',
    beginAgain: 'Start over again',
    beginAgainText: 'If you want to go through all the steps of setting up the application again, click “Start”. All entered data will be deleted.',
    deleteAccount: 'Delete accounts',
    delete: 'Delete',
    deleteAccountText: 'Deleting an account will also remove all links to it.',
    security: 'Security setup',
    securityGoogle: 'You can set up authorization with Google Authenticator',
    currencySaved: 'Currency saved',
    languageSaved: 'Language changed',
    config: 'System setup',
    mainCurrency: 'Main currency',
    enable2Fa: 'Turn on 2FA',
    disable2Fa: 'Turn off 2FA',
    privateConfig: 'Privacy settings',
    currentPassword: 'Current password',
    adminShow: 'The admin can see the numbers in the expenses',
    adminOperation: 'Fin. adviser is prohibited from carrying out operations from the integrated Tinkoff investment account',
    language: 'Language',
  },
  incomeOutcome: {
    outcome: 'Expenses',
    income: 'Income',
    addOutcome: 'Add expense',
    addIncome: 'Add income',
    date: 'Date',
    sum: 'Sum',
    category: 'Category',
    subcategory: 'Subcategory',
    tag: 'Tag',
    desc: 'Description',
    buyAccount: 'Withdrawal account',
    sellAccount: 'Enrollment account',
    unsetFilter: 'Reset filter',
    filter: 'Filter',
    addCategory: 'Add category',
    month: 'Month',
    period: 'Period',
    configCategory: 'Customize categories',
    incomeOutcomePerPeriod: 'Income and expenses for the period',
    monthes12: 'For 12 months',
    byYear: 'By years',
    configBudget: 'Set up budgets',
    configBudget2: 'Set up budget',
    overspend: 'You overspending',
    leftBudget: 'Left in the budget',
    configTemplate: 'Customize templates',
    noBudgets: 'No budgets created',
    create: 'Create',
    select: 'Select',
    countMoney: 'Amount of money at the beginning of the period',
    incomes: 'Incomes',
    changeActives: 'Changes in personal assets',
    invest: 'Investment flow',
    unconfirmedOperation: 'Unconfirmed transactions',
    selectTemplate: 'Template selection',
    templateWithoutName: 'Untitled template',
    from: 'from',
    to: 'to',
    incomeTo: 'Incomes',
  },
  plan: {
    chartLife: 'Life cycle chart',
    positiveScenario: 'Positive scenario',
    negativeScenario: 'Negative scenario',
    neutralScenario: 'Neutral scenario',
    period: 'Period',
    positive: 'Positive',
    neutral: 'Neutral',
    negative: 'Negative',
    middle: 'Middle',
    tax: 'Tax',
    addIncome: 'Additional income',
    clear: 'Clear',
    outcome: 'Expense',
    addOutcome: 'Additional expenses',
    obligations: 'Liabilities',
    goals: 'Goals',
    diff: 'balance',
    probability: 'Probability',
    index: 'Indexing',
    inCarier: 'During a career',
    inRetire: 'Retired',
    ageRetire: 'Retirement age',
    ageOnRetire: 'How many years in retirement',
    monthCarierStart: 'Career start month',
    extended: 'In detail',
    short: 'Brief',
    carier: 'Career',
    retire: 'Retired',
    monthPayment: 'Payout months',
    allFor: 'Total for ',
    year: 'y.',
    hands: 'On hands',
    contract: 'Under contract',
    toAllPeriods: 'Spread over the following periods',
    reachGoal: 'Goal achievement',
    yes: 'Yes',
    no: 'No',
    nameGoal: 'Name of goal',
    countGoal: 'Number of payouts',
    startGoal: 'Start date of accumulation',
    endGoal: 'By what date',
    percentGoal: 'Rate (annual)',
    alreadySaved: 'How much has been postponed',
    inflationGoal: 'Inflation rate (annual)',
    closePayment: 'Next payment',
    retireAgePayment: 'How many years to pay in retirement',
    currentSpendPerYear: 'Current expense per year',
    periodPaymentGoal: 'Select recharge period',
    createGoal: 'Create goal',
    newGoal: 'New goal',
    editGoal: 'Edit goal',
    sortGoal: 'Goal sorting',
    finPlan: 'Financial planning',
    priority: 'Goal priority',
    typeGoal: 'Type of goal',
    countPayment: 'Number of payments',
    calcPayment: 'Monthly payment calculation',
    futureValue: 'Future value',
    tabChart: 'Schedule',
    tabByGroups: 'By groups',
    scenario: 'Scenario',
    shortGoal: 'Short term goal',
    middleGoal: 'Medium term goal',
    longGoal: 'Long term goal',
    withBind: 'With binding',
    withoutBind: 'No binding',
    rentier: 'Rentier',
    rentierWithInflation: 'Rentier with inflation',
    retireFund: 'Pension fund',
    control: 'Take account of',
    notControl: 'Ignore',
    goalsPriority: 'Goal priority',
    sumPayment: 'Sum',
    datePayment: 'Date',
    payment: 'payments',
    monthlyNotEqual: 'Monthly excess of income over expenses',
    planPayments: 'Planned payments',
    freeLeft: 'Free balance',
    lessThan100: 'The amount must be less than 100',
    spreadToNextMonthes: 'Spread over the next months',
  },
  invest: {
    stats: 'Statistics',
    currentPriceActives: 'Current value of assets',
    avgPriceActives: 'Average asset value',
    profitPerPeriod: 'Profitability for the period',
    expectedProfit: 'Expected return',
    profitYear: 'Annual yield',
    maxLose: 'Maximum drawdown',
    koefSharp: 'Sharpe ratio',
    koefSortino: 'Sortino coefficient',
    byGroup: 'By group',
    byType: 'By type',
    byAccount: 'By account',
    value: 'Portfolio value',
    term: 'Term',
    period: 'Period',
    portfolio: 'Portfolio',
    div: 'Dividends',
    invest: 'List of investments',
    archiveInvest: 'Deal archive',
    addInvest: 'Add asset',
    buySellActive: 'Buying/selling asset',
  },
  profile: {
    profile: 'Profile',
    photo: 'Photo',
    enterName: 'Enter your name',
    enterEmail: 'Enter email',
    enterSurname: 'Enter Surname',
    enterPhone: 'Enter telephone number',
    enterMiddlename: 'Enter middle name',
    enterBirthDate: 'Date of Birth',
    enterStartDate: 'Date of entry of information about assets',
    tutorial: 'Go through certain application setup steps',
    start: 'Start',
    begin: 'Starting',
    tutorial1: 'Budget module',
    tutorial2: 'Investment list module',
    tutorial3: 'Planning module',
    tutorial4: 'Filling in accounts, assets',
    tutorial5: 'Get trained',
    finish: 'Complete the tutorial',
    next: 'Next',
    lesson1: {
      page1: 'Profile page, you must fill in the required fields and click the "Save" button',
      page2: 'The “Wealth” window shows a summary of your finances',
      page3: 'To go to the main dashboard, click.',
      page4: 'This dashboard will show you your current financial situation.',
      page5: 'Select a section from the menu"Accounts / Assets / Liabilities"',
      page6: 'The "Accounts" block contains information about all balances',
      page7: 'To create an account, you must click the "Create an account" button',
      page8: 'Form for creating a new account',
      page9: 'Name the New account, for example "BofA_salary".\nWe do not recommend writing just the name of the bank, as it may have several accounts, or the accounts of your plow / partner with whom you jointly keep financial records.',
      page10: 'Select an account type. Each type has its own logic. If you dont know for sure, choose a custom account type.',
      page11: 'Come up with a name for the sub-account, for example "Salary".',
      page12: 'Display your current account balance.',
      page13: 'Select currency.',
      page14: 'Deposit multiple accounts in the same bank, for example, there are accounts in different currencies.',
      page15: 'Please note that deposits for bank accounts are issued separately from current accounts..',
      page16: 'After completing the filling, we show the invoices.',
      page17: 'Fill out the form and save the accounts',
      page18: 'Now you can see your accounts and their balances',
      page19: 'Training completed',
    },
    fillForm: 'Fill in personal data',
  }
}

