import styled, {keyframes} from 'styled-components'
import zindex from '../zindex'

let close = require('./images/close.svg').default;
let loaderSvg = require('./../../assets/images/loader.svg').default;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
export const Overlay = styled.div`
    overflow-y: auto;
    position: fixed;
    top: 0!important;
    left: 0!important;
    width: 100%;
    height: 100vh;
    text-align: center;
    vertical-align: middle;
    background-color: rgba(0,0,0,.7);
    z-index: ${zindex.modal};
    // transition: opacity 1s;
    //
    //       opacity: 0;
    // ${props => props.show === true && `
    //     opacity: 1;
    // `}

    ::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #F5F5F5;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #D2D1D1;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #D2D1D1;
    }
`

export const Wrapper = styled.div`
    position: relative;
    top: 0!important;
    left: 0!important;
    width: 100%;
    height: 100vh;
    line-height: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: ${zindex.modal};
`

export const WrapperLoader = styled.div`
    position: relative;
    top: 0!important;
    left: 0!important;
    width: 100%;
    height: 100vh;
    line-height: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: ${zindex.modal};
    
`

export const BlockLoader = styled.div`
    background-color: #fff;
    position: absolute;
    width: 100px;
    height: 100px;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    border-radius: 24px;
`

export const Modal = styled.div`
    padding-top: 100px !important;
    padding-bottom: 100px !important;
    display: inline-block !important;
    position: relative;
    transition: opacity 1s;
    
    ${props => props.size === undefined && `
        min-width: 70%;
        max-width: 70%;
    `}
    
    ${props => props.size === 'extra-small' && `
        width: 100%;
        max-width: 440px;
    `}
    
    ${props => props.size === 'very-small' && `
        width: 100%;
        max-width: 640px;
    `}
    
    ${props => props.size === 'small' && `
        width: 100%;
        max-width: 786px;
    `}
    
    ${props => props.size === 'medium' && `
        width: 100%;
        max-width: 900px;
    `}
    
    ${props => props.size === 'large' && `
        width: 100%;
        max-width: 1200px;
    `}
    
    ${props => props.size === 'extra-large' && `
        width: 100%;
        max-width: 1400px;
    `}
        
    ${props => props.size === 'avatar' && `
        width: 100%;
        max-width: 568px;
    `}
`

export const ModalHeader = styled.div`
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-start;
    padding: 20px 20px 0 40px;
    position: relative;
    color: #363636;
    flex-grow: 1;
    flex-shrink: 0;
    font-size: 24px;
    line-height: 1;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    font-weight: 600;
    height: 60px;
        
    & > div{
      width: 100%;
      text-align: left;
    }
`

export const ModalContent = styled.div`
  background-color: white;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 5px 20px 5px 20px;
  overflow: visible;
  
  
  ${props => props.type === 'scroll' && `
    overflow: auto;
    height: 600px;
  `}
  
  ::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #F5F5F5;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #D2D1D1;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #D2D1D1;
  }
`

export const ModalFooter = styled.div`
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-start;
    padding: 5px 20px 20px 20px;
    position: relative;
    color: #363636;
    flex-grow: 1;
    flex-shrink: 0;
    font-size: 1.5rem;
    flex-wrap: wrap;
    line-height: 1;
    border-bottom: 1px solid #dbdbdb;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
`

export const ModalClose = styled.span`
    background-color: #F5F5F5;
    padding: 4px;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 72px;
    cursor: pointer;
      
    & > div{
      background: no-repeat url(${close}) center center;
      width: 32px;
      height: 32px;
    }
`

export const ModalLoader = styled.span`
    animation: ${rotate} 2s linear infinite;
    background-image: url(${loaderSvg});
    background-size: 100%, contain, cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 75px;
    height: 75px;
    animation: rotate 2s linear infinite;
    margin: 14px auto;
    display: block;
`
