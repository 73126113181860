import styled from 'styled-components'

export const Img = styled.img`
    width: 122px;
    height: 148px;
`
export const ImgContainer = styled.div`
    text-align: center;
    padding: 120px 0 26px 0;
`

export const NotFoundContainer = styled.div`
    text-align: center;
`

export const Title = styled.h3`
    font-size: 24px;
    font-weight: 600;
`

export const Text = styled.p`
    max-width: 480px;
    font-size: 14px;
    font-weight: 400;
    margin: 0 auto;     
`

