import {createReducer} from 'redux-act'
import * as actions from '../actions/jobs'

let jobs = {
};

const handleCreateJob = (state, id) => {

  if(typeof id === 'string' || typeof id === 'number')
  {
    state[id] = {percent: 0};

    return state
  }else{
    return state;
  }
}

const handleUpdateJob = (state, id, percent) => {

  if(typeof id === 'string' || typeof id === 'number')
  {
    state[id] = {percent: percent};

    return state
  }else{
    return state;
  }
}

const handleRemoveJob = (state, id) => {

  if(typeof id === 'string' || typeof id === 'number')
  {
    delete state[id];

    return state
  }else{
    return state;
  }
}


const reducer = createReducer({
  [actions.handleCreateJob]: handleCreateJob,
  [actions.handleUpdateJob]: handleUpdateJob,
  [actions.handleRemoveJob]: handleRemoveJob,
}, jobs)

export default reducer
