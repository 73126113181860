import React, {PureComponent} from 'react'
import Layout from './../../pagesRouter/Layout/Layout'
import HeaderLanding from '../../interface/landing/header/Header'
import UnexpectedError from './UnexpectedError'
import {Api} from "laravel-request";
import {Url} from "finhelper"

class ErrorBoundary extends PureComponent
{
  constructor(props)
  {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info)
  {
    try{
      let url = Url.getCurrentUrl();

      Api.post('error-log', 'store', {
        message: error.message,
        trace: error.stack,
        url: url,
        info: info
      }).call();
    }catch(e){
      console.error(e)
    }

    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError)
    {
      

      return <Layout>
        <HeaderLanding/>
        <UnexpectedError/>
      </Layout>
    }
    return this.props.children;
  }
}

export default ErrorBoundary;