import React, {Fragment} from 'react';
import {forgot, handleCloseModal, requestForgot} from '../../actions/auth'
import {handleTitle} from '../../actions/interface'
import Form from "./Form";
import {connect} from "react-redux";
import {Container, GreyText, Text, Wrapper} from "./styles";
import {Button, Input} from "finform";
import {Row} from "#app/pages/styles";
import LocalStorageHelper from "#app/helpers/Cache/LocalStorageHelper";

class Forgot extends Form
{
  constructor(props)
  {
    super(props);

    let email = localStorage.getItem(LocalStorageHelper.AUTH_EMAIL) || '';

    this.state = {
      ...this.state,
      form: {
        email: email,
        password: '',
        password_confirmation: '',
        code: '',
        captchaValue: '',
        form: 'client'
      },
      formErrors: {},
      code: false,
      submitted: false,
      checked: false
    };

    this.props.handleTitle(this.props.i18n.auth.enter);
  }

  submitRequestForgotForm()
  {
    this.setState({
      submitted: true
    }, () =>
    {
      this.props.requestForgot({
        form: this.state.form,
        success: (response, status, xhr) =>
        {
          this.setState({
            code: true,
            submitted: false
          });
        },
        error: (errors) =>
        {
          this.setState({
            submitted: false,
          });
        },
        obj: this
      });
    });
  }

  submitForgotForm()
  {
    this.setState({
      submitted: true
    }, () =>
    {
      this.props.forgot({
        form: this.state.form,
        success: (response, status, xhr) =>
        {
          
          this.props.handleCloseModal();

          this.setState({
            form: {
              email: '',
              password: '',
              password_confirmation: '',
              captchaValue: '',
              code: '',
            },
            submitted: false
          }, () =>
          {
            if (typeof this.props.callback === 'function')
            {
              this.props.callback();
            }
          });
        },
        error: (errors) =>
        {
          this.setState({
            submitted: false,
          });
        },
        obj: this,
        callback: () => {

        }
      });
    });
  }

  submitForm()
  {
    if (this.state.code)
    {
      this.submitForgotForm();
    } else
    {
      this.submitRequestForgotForm()
    }
  }

  renderForm()
  {
    if (this.state.code) {
      return this.renderCodeForm();
    } else {
      return this.renderForgotForm()
    }
  }

  handleEnter(e)
  {
    if (e.key === 'Enter' || e.keyCode === 13) {
      this.submitForm();
    }
  }

  renderCodeForm() {
    return <Wrapper>
      <Container>
        <Fragment>
          <Text>{this.props.i18n.auth.codeAdvise} {this.state.form.email}</Text>
          <Row>
            <Input
              id='forgot-code'
              type="text"
              name="code"
              value={this.state.form.code}
              onKeyPress={(e) => {
                this.handleEnter(e)
              }}
              onChange={(e, { name, value }) =>
              {
                this.setState((prv) =>
                {
                  prv.form.code = value;
                  if(prv.formErrors)
                  {
                    prv.formErrors.code = {};
                  }

                  return prv;
                });
              }}
              placeholder={this.props.i18n.auth.code}
              errors={this.state.formErrors}
            />
          </Row>
          <h3>{this.props.i18n.auth.createNewPassword}</h3>
          <Row>
            <Input
              id='forgot-password'
              type="password"
              name="password"
              value={this.state.form.password}
              onKeyPress={(e) => {
                this.handleEnter(e)
              }}
              onChange={(e, { name, value }) =>
              {
                this.setState((prv) =>
                {
                  prv.form.password = value;
                  if(prv.formErrors)
                  {
                    prv.formErrors.password = {};
                  }

                  return prv;
                });
              }}
              placeholder={this.props.i18n.auth.newPassword}
              errors={this.state.formErrors}
            />
          </Row>
          <Row>
            <Input
              id='forgot-password_confirmation'
              type="password"
              name="password_confirmation"
              value={this.state.form.password_confirmation}
              onKeyPress={(e) => {
                this.handleEnter(e)
              }}
              onChange={(e, { name, value }) =>
              {
                this.setState((prv) =>
                {
                  prv.form.password_confirmation = value;
                  if(prv.formErrors)
                  {
                    prv.formErrors.password_confirmation = {};
                  }

                  return prv;
                });
              }}
              placeholder={this.props.i18n.auth.newPasswordRepeat}
              errors={this.state.formErrors}
            />
          </Row>
        </Fragment>
        <GreyText>{this.props.i18n.auth.issue}</GreyText>
        <Row>
          <Button flex id='forgot-submit' onClick={() => {
            this.submitForm();
          }}>{this.props.i18n.auth.save}</Button>
        </Row>
      </Container>
    </Wrapper>
  }

  renderForgotForm() {
    return <Wrapper>
      <Container>
        <Row>
          <Input
            id='forgot-email'
            type="text"
            name="email"
            value={this.state.form.email}
            onKeyPress={(e) => {
              this.handleEnter(e)
            }}
            onChange={(e, { name, value }) =>
            {
              localStorage.setItem('auth_email', value)
              this.setState((prv) =>
              {
                prv.form.email = value;
                if(prv.formErrors)
                {
                  prv.formErrors.email = {};
                }

                return prv;
              });
            }}
            placeholder={'Email'}
            errors={this.state.formErrors}
          />
        </Row>
        <Row>
          <Button flex className='style2' id='forgot-submit' onClick={() => {
            this.submitForm();
          }}>{this.props.i18n.auth.restore}</Button>
        </Row>
      </Container>
    </Wrapper>
  }

  render()
  {
    return this.renderForm();
  }
}

const enhance = connect(
  state => ({
    i18n: state.i18n,
  }),
  { forgot, requestForgot, handleTitle, handleCloseModal}
)

export default enhance(Forgot)
