import {createAction} from 'redux-act'

export const startScenario = createAction('START_SCENARIO')
export const setStepScenario = createAction('SET_STEP_SCENARIO')
export const backScenario = createAction('BACK_SCENARIO')
export const moveScenario = createAction('MOVE_SCENARIO')
export const finishScenario = createAction('FINISH_SCENARIO')

export const getFormData = createAction('GET_FORM_DATA')
export const handleFormDataStatus = createAction('HANDLE_FORM_DATA_STATUS')
export const handleFormData = createAction('HANDLE_FORM_DATA')
export const handleStartTutorial = createAction('HANDLE_START_TUTORIAL')
export const handleEndTutorial = createAction('HANDLE_END_TUTORIAL')
export const handleStartDemo = createAction('HANDLE_START_DEMO')
export const handleEndDemo = createAction('HANDLE_END_DEMO')

export const getFormAvatar = createAction('GET_FORM_AVATAR')
export const handleFormAvatar = createAction('HANDLE_FORM_AVATAR')

export const bindHelperGotoFunc = createAction('BIND_HELPER_GOTO_FUNC')
export const bindHelperCloseFunc = createAction('BIND_HELPER_CLOSE_FUNC')

export const callHelperGotoFunc = createAction('CALL_HELPER_GOTO_FUNC')
export const callHelperCloseFunc = createAction('CALL_HELPER_CLOSE_FUNC')
