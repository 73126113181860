import React, {Fragment} from 'react';
import {handleCloseModal, register, requestRegister} from '../../actions/auth'
import {handleTitle} from '../../actions/interface'
import Form from "./Form";
import {connect} from "react-redux";
import {Container, GreyText, Text, Wrapper} from "./styles";
import {Button, Input} from "finform";
import {Row} from "#app/pages/styles";
import {NotifyManager} from "notify-component";
import {Caption} from "#app/components/Text/styles";
import LocalStorageHelper from "#app/helpers/Cache/LocalStorageHelper";

class Register extends Form {
  constructor(props) {
    super(props);

    let email = localStorage.getItem(LocalStorageHelper.AUTH_EMAIL) || '';

    this.state = {
      ...this.state,
      form: {
        email: email,
        password: '',
        password_confirmation: '',
        captchaValue: '',
        code: '',
        role: 'client',
        promo_code: '',
        form: 'client'
      },
      formErrors: {},
      code: false,
      submitted: false,
      checked: false
    };
  }

  submitRequestRegisterForm() {
    this.setState({
      submitted: true
    }, () => {
      this.props.requestRegister({
        form: this.state.form,
        success: (response, status, xhr) => {
          this.setState({
            code: true,
            submitted: false
          });
        },
        error: (xhr) => {
          this.setState({
            submitted: false,
          });
        },
        obj: this
      });
    });
  }

  submitRegisterForm() {
    this.setState({
      submitted: true
    }, () => {
      this.props.register({
        form: this.state.form,
        success: (response, status, xhr) => {
          

          var header = xhr.getResponseHeader('Authorization');

          if(header)
          {
            localStorage.setItem(LocalStorageHelper.API_TOKEN, header);
            this.props.handleCloseModal();

            this.setState({
              form: {
                email: '',
                password: '',
                password_confirmation: '',
                captchaValue: '',
                code: '',
                role: 'client',
                promo_code: '',
              },
              submitted: false
            }, () => {
              if (typeof this.props.callback === 'function') {
                this.props.callback();
              }
            });
          }else{
            NotifyManager.error('Ошибка авторизации')
          }
        },
        error: (xhr) => {
          this.setState({
            submitted: false,
          });
        },
        obj: this
      });
    });
  }

  handleEnter(e)
  {
    if (e.key === 'Enter' || e.keyCode === 13) {
      this.submitForm();
    }
  }

  submitForm() {
    if (this.state.code) {
      this.submitRegisterForm();
    } else {
      this.submitRequestRegisterForm()
    }
  }

  renderForm()
  {
    if (this.state.code) {
      return this.renderCodeForm();
    } else {
      return this.renderRegisterForm()
    }
  }

  renderCodeForm() {
    return <Wrapper>
      <Container>
         <Fragment>
          <Text>{this.props.i18n.auth.codeAdvise} {this.state.form.email}</Text>
          <Row>
            <Input
              id='register-code'
              type="text"
              name="code"
              value={this.state.form.code}
              onKeyPress={(e) => {
                this.handleEnter(e)
              }}
              onChange={(e, {name, value}) => {
                this.setState((prv) => {
                  prv.form.code = value;
                  prv.formErrors.code = {};

                  return prv;
                });
              }}
              placeholder={this.props.i18n.auth.code}
              errors={this.state.formErrors}
            />
          </Row>
        </Fragment>
        <GreyText>{this.props.i18n.auth.issue}</GreyText>
        <Row>
          <Button className="style2" flex id='register-submit' onClick={() => {
            this.submitForm()
          }}>{this.props.i18n.auth.confirm}</Button>
        </Row>
      </Container>
    </Wrapper>
  }

  renderRegisterForm() {
    return <Wrapper>
      <Container>
        <Row>
          <Input
            id='register-email'
            type="text"
            name="email"
            autoComplete='on'
            value={this.state.form.email}
            onKeyPress={(e) => {
              this.handleEnter(e)
            }}
            onChange={(e, {name, value}) => {
              localStorage.setItem('auth_email', value)
              this.setState((prv) => {
                prv.form.email = value;
                if (prv.formErrors) {
                  prv.formErrors.email = {};
                }


                return prv;
              });
            }}
            placeholder={'Email'}
            errors={this.state.formErrors}
          />
        </Row>
        <Row>
          <Input
            id='register-password'
            type="password"
            name="password"
            value={this.state.form.password}
            onKeyPress={(e) => {
              this.handleEnter(e)
            }}
            onChange={(e, {name, value}) => {
              this.setState((prv) => {
                prv.form.password = value;
                if (prv.formErrors) {
                  prv.formErrors.password = {};
                }

                return prv;
              });
            }}
            placeholder={this.props.i18n.auth.password}
            errors={this.state.formErrors}
          />
        </Row>
        <Row>
          <Input
            id='register-password_confirmation'
            type="password"
            name="password_confirmation"
            value={this.state.form.password_confirmation}
            onKeyPress={(e) => {
              this.handleEnter(e)
            }}
            onChange={(e, {name, value}) => {
              this.setState((prv) => {
                prv.form.password_confirmation = value;
                if (prv.formErrors) {
                  prv.formErrors.password_confirmation = {};
                }

                return prv;
              });
            }}
            placeholder={this.props.i18n.auth.passwordRepeat}
            errors={this.state.formErrors}
          />
        </Row>
        <Row>
          <Input
            id='register-promo_code'
            type="text"
            name="promo_code"
            value={this.state.form.promo_code}
            onKeyPress={(e) => {
              this.handleEnter(e)
            }}
            onChange={(e, {name, value}) => {
              this.setState((prv) => {
                prv.form.promo_code = value;
                if (prv.formErrors) {
                  prv.formErrors.promo_code = {};
                }
                return prv;
              });
            }}
            placeholder={this.props.i18n.auth.promoCode}
            errors={this.state.formErrors}
          />
        </Row>
        <Row style={{margin: '10px 0'}}>
          <Caption>Регистрируясь на сайте Вы соглашаетесь с <a target="_blank" href="/agreement">Пользовательским соглашением</a> и <a target="_blank" href="/policy">Политикой по обработке персональных данных</a>.</Caption>
        </Row>
        <Row>
          <Button className="style2" flex id='register-submit' onClick={() => {
            this.submitForm();
          }}>{this.props.i18n.auth.toRegister}</Button>
        </Row>
      </Container>
    </Wrapper>
  }

  render()
  {
    return this.renderForm();
  }
}

const enhance = connect(
  state => ({
    i18n: state.i18n,
  }),
  {register, requestRegister, handleTitle, handleCloseModal}
)

export default enhance(Register)
