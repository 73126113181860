import {Api} from "laravel-request";
import {AccountConstants, Active, ActiveModel} from "finhelper";
import moment from "moment/moment";

export default class BalanceHelper
{
  /**
   *
   * @type {boolean}
   */
  static loading = false;

  /**
   *
   * @param data
   */
  static convertDataToActives(data)
  {
    data.properties = data.properties?.map((item) => {
      return new ActiveModel(item)
    })
    data.invests = data.invests?.map((item) => {
      return new ActiveModel(item)
    })
    data.obligations = data.obligations?.map((item) => {
      return new ActiveModel(item)
    })
  }

  /**
   *
   * @param state
   * @param accounts
   * @param client
   * @param currency
   * @param courses
   * @return {Promise<*>}
   */
  static async loadData(state, accounts, client, currency, courses)
  {
    let now = moment();

    let currencyData = {
      currency_id: currency?.id
    };

    let accountBanks = [];

    await (new Promise((resolve) => {
      Active.getBalanceByDate(
        state,
        accounts,
        currencyData,
        client?.id,
        accountBanks,
        now,
        resolve,
        [
          AccountConstants.BROKER_ACCOUNT,
          AccountConstants.BANK_ACCOUNT,
          AccountConstants.CASH,
          AccountConstants.DIGIT_MONEY
        ],
        courses
      );
    }));

    await (new Promise((resolve) => {
      Active.getInvestsByDate(
        state,
        accounts,
        'invests',
        currencyData,
        client?.id,
        accountBanks,
        now,
        resolve
      );
    }));

    await (new Promise((resolve) => {
      Active.getPropertiesByDate(
        state,
        'properties',
        currencyData,
        client?.id,
        accountBanks,
        now,
        resolve
      );
    }));

    await (new Promise((resolve) => {
      Api.get('active', 'index', currencyData)
        .whereObligationType(true)
        .with('sell_trades')
        .with('valuations')
        .with('buy_currency')
        .with('sell_currency')
        .with('income_currency')
        .with('buy_account')
        .with('sell_account')
        .with('income_account')
        .with('payments')
        .with('invests', 'sell')
        .all((response) =>
        {
          state['obligations'] = response.data;
          resolve();
        });
    }));

    return state;
  }
}