import React from 'react';
import {handleCloseModal, handleForgotModal} from '../../actions/auth'
import {handleTitle} from '../../actions/interface'
import Form from "./Form";
import {connect} from "react-redux";
import {Container, Wrapper} from "./styles";
import {Row} from "#app/pages/styles";
import {Button, Input} from "finform";
import {NotifyManager} from "notify-component";
import {Api} from "laravel-request";
import withRouter from "#app/components/WithRouter/withRouter";

class RoboadvisorSubscribe extends Form {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      form: {
        email: '',
        fio: '',
      },
      success: false,
      type: '',
      formErrors: {},
      submitted: false,
    };

  }

  submitForm()
  {
    if(!this.state.submitted)
    {
      this.setState({
        submitted: true
      }, () => {
        Api.post('roboadvisor-subscribe', 'store', this.state.form)
          .withValidateForm(this)
          .call((response, status, xhr) => {
            NotifyManager.info('', 'Вы успешно подписались', 99999999)

            setTimeout(() => {
              this.props.navigate('/');
              this.props.handleCloseModal();
            }, 3000)
          }, (errors) => {
            this.setState({
              submitted: false
            })
          })
      });
    }
  }

  handleEnter(e)
  {
    if (e.key === 'Enter' || e.keyCode === 13) {
      this.submitForm();
    }
  }

  render() {
    return <Wrapper>
      <Container>
        <Row>
          <Input
            id='subscribe-fio'
            type="fio"
            name="fio"
            value={this.state.form.fio}
            onKeyPress={(e) => {
              this.handleEnter(e)
            }}
            onChange={(e, { name, value }) => {
              this.setState((prv) => {
                prv.form.fio = value;
                if(prv.formErrors)
                {
                  prv.formErrors.fio = {};
                }

                return prv;
              });
            }}
            placeholder={'Контакное лицо'}
            errors={this.state.formErrors}
          />
        </Row>
        <Row>
          <Input
            id='subscribe-email'
            type="text"
            name="email"
            autoComplete='on'
            value={this.state.form.email}
            onKeyPress={(e) => {
              this.handleEnter(e)
            }}
            onChange={(e, { name, value }) => {
              this.setState((prv) => {
                prv.form.email = value;
                if(prv.formErrors)
                {
                  prv.formErrors.email = {};
                }

                return prv;
              });
            }}
            placeholder={'Email'}
            errors={this.state.formErrors}
          />
        </Row>
        {/*{!this.state.success && <Row style={{margin: '10px 15px 15px 15px'}}>*/}
        {/*  <Title2 green>Успешно</Title2>*/}
        {/*</Row>}*/}
        <Row>
          <Button flex style={{margin: '5px'}} className='style2' id='subscribe-submit' onClick={() => {
            this.submitForm();
          }}>Уведомить</Button>
        </Row>
      </Container>
    </Wrapper>

  }
}

const enhance = connect(
  state => ({
    i18n: state.i18n,
  }),
  { handleTitle, handleForgotModal, handleCloseModal}
)

export {RoboadvisorSubscribe}

export default withRouter(enhance(RoboadvisorSubscribe))
