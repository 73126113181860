import {createAction} from 'redux-act'

export const getPlan = createAction('GET_PLAN')
export const savePlan = createAction('SAVE_PLAN')
export const handlePlan = createAction('HANDLE_PLAN')
export const setNeedReloadPlan = createAction('SET_NEED_RELOAD_PLAN')

export const getObligations = createAction('GET_OBLIGATIONS')
export const handleObligations = createAction('HANDLE_OBLIGATIONS')

export const getFactIncome = createAction('GET_FACT_INCOME')
export const handleFactIncome = createAction('HANDLE_FACT_INCOME')

export const getGoals = createAction('GET_GOALS')
export const handleGoals = createAction('HANDLE_GOALS')
export const handlePlanLogout = createAction('HANDLE_PLAN_LOGOUT')

export const setLoading = createAction('SET_LOADING')




