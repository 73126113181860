export default {
  loader: 500,
  header: 5,

  notify: 1500,
  sidebar: 300,
  body: 400,
  modal: 900,

  input: 50,
  placeholder: 40,
};