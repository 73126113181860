import React from 'react';
import {Modal, ModalClose, ModalContent, ModalFooter, ModalHeader} from '../../../modal/Modal';
import {Title2} from '../../../../components/Text/styles';
import {Button} from 'finform';
import {Row} from '#app/pages/styles';
import Register from '../../../../pages/Auth/Register';

const RegisterModal = ({ show, handleCloseModal, handleLoginModal, i18n }) => (
  <Modal
    size='extra-small'
    onClose={handleCloseModal}
    show={show}
    trigger={''}
  >
    <ModalHeader>
      <Title2>{i18n.auth.register}</Title2>
      <ModalClose onClick={handleCloseModal}><div /></ModalClose>
    </ModalHeader>
    <ModalContent>
      <Register />
    </ModalContent>
    <ModalFooter>
      <Row>
        <Button flex className='style2' id='login-modal-button' onClick={(e) => {
          e.preventDefault();
          handleLoginModal();
        }}>{i18n.auth.enter}</Button>
      </Row>
    </ModalFooter>
  </Modal>
);

export default RegisterModal;