import {createReducer} from 'redux-act';
import * as actions from '../actions/auth';
import {Tracker, Url} from "finhelper";
import CacheHelper from "#app/helpers/Cache/CacheHelper";
import LocalStorageHelper from "#app/helpers/Cache/LocalStorageHelper";

const LANGUAGE_RU = '/ru/';
const LANGUAGE_EN = '/en/';
const URL_LOGIN = ['/login', '/en/login', '/ru/login'];
const URL_SUBSCRIBE = '/roboadvisor';

// Вспомогательная функция для определения языка
const getLanguage = () => {
  let storedLanguage = localStorage.getItem(LocalStorageHelper.LANGUAGE);
  const url = Url.getCurrentUrl();

  if (url.includes(LANGUAGE_RU)) {
    localStorage.setItem(LocalStorageHelper.LANGUAGE, 1);
    return 1;
  } else if (url.includes(LANGUAGE_EN)) {
    localStorage.setItem(LocalStorageHelper.LANGUAGE, 2);
    return 2;
  }

  return storedLanguage === '1' || storedLanguage === null ? 1 : 2;
};

const saveUrlParamsToLocalStorage = () => {
  const params = Url.getParams();

  if (params.crm_client_id) {
    localStorage.setItem('crm_client_id', params.crm_client_id);
  }

  if (params.token) {
    localStorage.setItem('token', params.token);
  }
};

// Инициализация состояния пользователя
const initializeUserState = () => {
  const url = Url.getCurrentUrl();
  const language = getLanguage();

  saveUrlParamsToLocalStorage();

  return {
    data: {},
    language,
    auth: false,
    showLoginModal: URL_LOGIN.includes(url),
    showRegisterModal: false,
    showForgotModal: false,
    showSubscribeModal: url === URL_SUBSCRIBE,
  };
};

const handleLanguage = (state, language) => ({
  ...state,
  language,
});

const handleGetUser = (state, response) => (
  response?.result === 'success'
    ? {
      ...state,
      data: response.data,
      auth: true,
    }
    : state
);

const handleLogout = (state) => {

  CacheHelper.clearAll();

  return {
    ...state,
    data: {},
    auth: false,
  };
};

const handleModalVisibility = (state, modalState) => ({
  ...state,
  ...modalState,
});

// Редьюсер
const reducer = createReducer({
  [actions.handleGetUser]: handleGetUser,
  [actions.handleLogout]: handleLogout,
  [actions.handleLoginModal]: (state) => handleModalVisibility(state, {
    showLoginModal: true,
    showRegisterModal: false,
    showForgotModal: false,
  }),
  [actions.handleForgotModal]: (state) => handleModalVisibility(state, {
    showForgotModal: true,
    showLoginModal: false,
    showRegisterModal: false,
  }),
  [actions.handleRegisterModal]: (state) => {
    Tracker.reachGoal('register_click');
    return handleModalVisibility(state, {
      showRegisterModal: true,
      showForgotModal: false,
      showLoginModal: false,
    });
  },
  [actions.handleCloseModal]: (state) => handleModalVisibility(state, {
    showLoginModal: false,
    showRegisterModal: false,
    showForgotModal: false,
    showSubscribeModal: false,
  }),
  [actions.handleLanguage]: handleLanguage,
}, initializeUserState());

export default reducer;