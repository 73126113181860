import React from 'react';
import {handleCloseModal, handleForgotModal, login} from '../../actions/auth'
import {handleTitle} from '../../actions/interface'
import Form from "./Form";
import {connect} from "react-redux";
import {Container, HeaderForget, HeaderForgetContainer, Wrapper} from "./styles";
import {Row} from "#app/pages/styles";
import {Button, Input} from "finform";
import {NotifyManager} from "notify-component";
import LocalStorageHelper from "#app/helpers/Cache/LocalStorageHelper";

class Login extends Form {
  constructor(props) {
    super(props);

    let email = localStorage.getItem(LocalStorageHelper.AUTH_EMAIL) || '';

    this.state = {
      ...this.state,
      form: {
        email: email,
        password: '',
        captchaValue: '',
        form: 'client'
      },
      type: '',
      formErrors: {},
      submitted: false,
      checked: false
    };

  }

  submitForm()
  {
    this.setState({
      submitted: true
    }, () => {
      this.props.login({
        form: this.state.form,
        success: (response, status, xhr) => {
          var header = xhr.getResponseHeader('Authorization');

          if(header)
          {
            localStorage.setItem(LocalStorageHelper.API_TOKEN, header);
            
            this.props.handleCloseModal();

            this.setState({
              form: {
                email: '',
                password: '',
              },
              submitted: false
            }, () => {
              if(typeof this.props.callback === 'function')
              {
                this.props.callback();
              }
            });
          }else{
            NotifyManager.error('Ошибка авторизации')
          }
        },
        error: (xhr) => {
          if(this.state.type === '')
          {
            let enable = false;
            if (xhr?.responseJSON?.meta?.errors?.code?.[0])
            {
              if(xhr.responseJSON.meta.errors.code[0] === 'Неверный 2FA код')
              {
                enable = true
              }

              this.setState({
                type: enable ? '2fa' : '',
                submitted: false
              });
            }
          }else{
            this.setState({
              submitted: false
            });
          }
        },
        obj: this,
      });
    });
  }

  handleCheckbox(e)
  {
    e.preventDefault();
    this.setState({
      checked: !this.state.checked
    })
  }

  handleEnter(e)
  {
    if (e.key === 'Enter' || e.keyCode === 13) {
      this.submitForm();
    }
  }

  render() {
    return <Wrapper>
      <Container>
        <Row>
          <Input
            id='login-email'
            type="text"
            name="email"
            autoComplete='on'
            value={this.state.form.email}
            onKeyPress={(e) => {
              this.handleEnter(e)
            }}
            onChange={(e, { name, value }) => {
              localStorage.setItem('auth_email', value)
              this.setState((prv) => {
                prv.form.email = value;
                if(prv.formErrors)
                {
                  prv.formErrors.email = {};
                }

                return prv;
              });
            }}
            placeholder={'Email'}
            errors={this.state.formErrors}
          />
        </Row>
        <Row>
          <Input
            id='login-password'
            type="password"
            name="password"
            value={this.state.form.password}
            onKeyPress={(e) => {
              this.handleEnter(e)
            }}
            onChange={(e, { name, value }) => {
              this.setState((prv) => {
                prv.form.password = value;
                if(prv.formErrors)
                {
                  prv.formErrors.password = {};
                }

                return prv;
              });
            }}
            placeholder={this.props.i18n.auth.password}
            errors={this.state.formErrors}
          />
        </Row>
        {this.state.type === '2fa' && <Row>
          <Input
            id='login-code'
            type="code"
            name="code"
            value={this.state.form.code}
            onKeyPress={(e) => {
              this.handleEnter(e)
            }}
            onChange={(e, { name, value }) => {
              this.setState((prv) => {
                prv.form.code = value;
                if(prv.formErrors)
                {
                  prv.formErrors.code = {};
                }

                return prv;
              }, () => {
                if(this.state.form.code && typeof this.state.form.code === 'string' && this.state.form.code.length === 6)
                {
                  this.submitForm();
                }
              });
            }}
            placeholder={this.props.i18n.auth.fa}
            errors={this.state.formErrors}
          />
        </Row>}
        <Row>
          <HeaderForgetContainer>
            <HeaderForget id={'forgot-button'} onClick={(e) =>
            {
              e.preventDefault();

              this.props.handleForgotModal();
            }}>{this.props.i18n.auth.forgotPassword}</HeaderForget>
          </HeaderForgetContainer>
        </Row>
        <Row>
          <Button flex className='style2' id='login-submit' onClick={() => {
            this.submitForm();
          }}>{this.props.i18n.auth.enter}</Button>
        </Row>
      </Container>
    </Wrapper>

  }
}

const enhance = connect(
  state => ({
    i18n: state.i18n,
  }),
  { login, handleTitle, handleForgotModal, handleCloseModal}
)

export {Login}

export default enhance(Login)
