import React from 'react'
import {put, select} from 'redux-saga/effects'
import * as jobsActions from '../actions/jobs'
import {ActiveConstants} from "finhelper"
import {NotifyManager} from "notify-component";

function getTextByType(type)
{
  switch(parseInt(type))
  {
    case ActiveConstants.ZENMONEY:
      return 'Синхронизация с Дзенмани';
    case ActiveConstants.TINKOFF:
      return 'Синхронизация с "Тинькофф инвестиции"';
    case ActiveConstants.GOAL:
      return 'Идёт перерассчёт целей';
    case ActiveConstants.STRATEGY:
      return 'Идёт рассчёт графика';
    case ActiveConstants.GOAL_RECALC:
      return 'Идёт перерассчёт целей';
  }
}

export const handleNotify = function* (response)
{
  if (response.data)
  {
    let jobs = response.data.jobs;
    const state = yield select();

    if (jobs)
    {
      for (let i = 0; i < jobs.length; i++)
      {
        let job = jobs[i];
        if(typeof state.jobs[job.job_id] === 'undefined')
        {
          NotifyManager.once(job.job_id, '', getTextByType(job.type_id), 'waiting', 99999999999);
          yield put(jobsActions.handleCreateJob(job.job_id));
        }
      }
    }

    let jobKeys = Object.keys(state.jobs);

    for (let n = 0; n < jobKeys.length; n++)
    {
      let percent = null;
      let jobKey = parseInt(jobKeys[n]);
      let found = false;
      for (let i = 0; i < jobs.length; i++)
      {
        let job = jobs[i];
        if(job.job_id === jobKey)
        {
          found = true;
          percent = job.percent;
        }
      }

      if(!found)
      {
        NotifyManager.delete(jobKey);
        yield put(jobsActions.handleRemoveJob(jobKey));
      }else{
        NotifyManager.update(jobKey, parseFloat(percent));
      }
    }
  }
}


export default function* watcher() {
  // yield takeEvery('*', function* logger(action) {
  //  
  // })
}
