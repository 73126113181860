import React, {Component} from 'react';

class Form extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            load: false,
            recaptchaLoaded: false,
            formErrors: {},
        };
    }

    componentDidMount() {

    }

    asyncScriptOnLoad = () => {
        this.setState({ recaptchaLoaded: true });
    };

    onCaptchaChange = val => {
        if(val !== null)
        {
            this.setState({
                captchaValue: val
            }, () => {
                this.submitForm();
            });
        }
    };

    title()
    {

    }

    submitForm()
    {

    }
}

export default Form