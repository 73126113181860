import styled, {css} from 'styled-components'

export const sharedStyle = css`
  color: #000000;

  &.bold{
    font-weight: 600;
  }
  &.green{
    color: #7ED057!important;
  }
  &.black{
    color: #000!important;
  }
  &.red{
    color: #EF5E70!important;
  }
  &.grey{
    color: #7B7B7B!important;
  }
  &.white{
    color: #fff!important;
  }
  
  
  ${props => props.inline && `
     display: inline-block;
  `}
  ${props => props.left && `
     text-align: left;
  `}
  ${props => props.center && `
     text-align: center;
  `}
  ${props => props.right && `
     text-align: right;
  `}
  ${props => props.black && `
    color: #000;
  `}
  ${props => props.grey && `
    color: #7F818D;
  `}
  ${props => props.white && `
    color: #fff;
  `}
  ${props => props.red && `
    color: #EF5E70;
  `}
  ${props => props.green && `
    color: #7ED057;
  `}
  ${props => props.full && `
    flex-basis: 100%;
  `}
  
  ul{
    padding-left: 20px;
  }
`


export const Digits = styled.div`
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0;
  margin: 0;
  
  ${sharedStyle}

  @media (min-width: 750px) {
    font-size: 15px;
  }
  @media (min-width: 900px) {
    font-size: 20px;
  }
  @media (min-width: 1000px) {
    font-size: 25px;
  }
  @media (min-width: 1200px) {
    font-size: 30px;
  }
`
export const Title1 = styled.h1`
  font-size: 26px;
  font-weight: bold;
  margin: 0;
  
  ${sharedStyle}

  @media (min-width: 750px) {
    font-size: 11px;
  }
  @media (min-width: 900px) {
    font-size: 16px;
  }
  @media (min-width: 1000px) {
    font-size: 21px;
  }
  @media (min-width: 1200px) {
    font-size: 26px;
  }
`
export const Title2 = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  
  ${sharedStyle}

  @media (min-width: 750px) {
    font-size: 10px;
  }
  @media (min-width: 900px) {
    font-size: 15px;
  }
  @media (min-width: 1000px) {
    font-size: 20px;
  }
`
export const Title3 = styled.h3`
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  margin: 0;

  ${sharedStyle}

  @media (min-width: 750px) {
    font-size: 9px;
  }
  @media (min-width: 900px) {
    font-size: 12px;
  }
  @media (min-width: 1000px) {
    font-size: 15px;
  }
  @media (min-width: 1200px) {
    font-size: 18px;
  }
`
export const Title4 = styled.h4`
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  ${sharedStyle}

  @media (min-width: 750px) {
    font-size: 7px;
  }
  @media (min-width: 900px) {
    font-size: 10px;
  }
  @media (min-width: 1000px) {
    font-size: 13px;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`
export const DigitsSmall = styled.p`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  margin: 0;
  
  ${sharedStyle}

  @media (min-width: 750px) {
    font-size: 11px;
  }
  @media (min-width: 900px) {
    font-size: 14px;
  }
  @media (min-width: 1000px) {
    font-size: 17px;
  }
  @media (min-width: 1200px) {
    font-size: 20px;
  }
`
export const Body = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  
  ${sharedStyle}

  @media (min-width: 750px) {
    font-size: 8px;
  }
  @media (min-width: 900px) {
    font-size: 10px;
  }
  @media (min-width: 1000px) {
    font-size: 12px;
  }
  @media (min-width: 1200px) {
    font-size: 14px;
  }
`
export const BodyBold = styled.div`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  
  ${sharedStyle}

  @media (min-width: 750px) {
    font-size: 8px;
  }
  @media (min-width: 900px) {
    font-size: 10px;
  }
  @media (min-width: 1000px) {
    font-size: 12px;
  }
  @media (min-width: 1200px) {
    font-size: 14px;
  }
`
export const Link = styled.div`

`

export const Caption = styled.div`
  font-size: 12px;
  line-height: 16px;
  
  ${sharedStyle}

  @media (min-width: 750px) {
    font-size: 9px;
  }
  @media (min-width: 900px) {
    font-size: 10px;
  }
  @media (min-width: 1000px) {
    font-size: 11px;
  }
  @media (min-width: 1200px) {
    font-size: 12px;
  }
`
