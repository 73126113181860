import {createReducer} from 'redux-act';
import {Url} from "finhelper";
import LocalStorageHelper from "#app/helpers/Cache/LocalStorageHelper";

// Функция для получения языка
const getLanguageData = () => {
  const lang = localStorage.getItem(LocalStorageHelper.LANGUAGE);
  const url = Url.getCurrentUrl();

  if (url.includes('/ru/') || parseInt(lang) === 1 || lang === null) {
    return require('../translations/ru').default;
  } else if (url.includes('/en/')) {
    return require('../translations/en').default;
  }

  // По умолчанию возвращаем английскую локализацию
  return require('../translations/en').default;
};

// Инициализация данных с переводами
const data = getLanguageData();

// Создание редьюсера
const reducer = createReducer({}, data);

export default reducer;